<script>
import ParameterCard from "./components/parameter-card.vue";
import Draggable from "vuedraggable";
import { toNumber } from "lodash";
import { mapGetters } from 'vuex';

const EMPTY_PARAMETER = {
  definir_revisores: false,
  id: null,
  nome_fluxo: null,
  ordem: null,
  quantidade_revisores_aprovacao: null,
  revisores_tipo_id: [],
  sla: null,
  status: null,
};

export default {
  components: { Draggable, ParameterCard },
  data() {
    return {
      approvers: [],
      status: [],
      parameters: [
        {
          ...EMPTY_PARAMETER
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    resource() {
      return this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-parametrizacao`);
    }
  },
  created() {
    this.getApprovers();
    this.getStatus();
    this.doLoad();
  },
  methods: {
    async getStatus() {
      try {
        const response = await this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-parametrizacao/status`).get();
        this.status = response.sort(({ label: a }, { label: b }) => a.localeCompare(b));
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }

        this.status = [];
      }
    },
    async doDelete(id, index) {
      try {
        await this.resource.delete(id);
        this.$notify({
          title: "Sucesso!",
          text: `Parametro excluído com sucesso.`,
          type: "success",
          group: "geral",
          duration: 5000
        });
        this.parameters.splice(index, 1);
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async doLoad() {
      try {
        this.parameters = [];
        const response = await this.resource.get();
        this.parameters = [
          ...response.data.sort(({ ordem: a }, { ordem: b }) => a - b),
          {
            ...EMPTY_PARAMETER
          }
        ];
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }

        this.parameters = [
          {
            ...EMPTY_PARAMETER
          }
        ];
      }
    },
    async doReorder(data, prevIndex, nextIndex) {
      try {
        await this.resource.save({ ...data, ordem: nextIndex + 1 }, data.id);
        
        const [target] = this.parameters.splice(prevIndex, 1);
        this.parameters.splice(nextIndex, 0, target);

        for (let i = 0; i < this.parameters.length - 1; i++) {
          this.parameters[i].ordem = i + 1;
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async doSave(data, index) {
      try {
        const body = { ...data, definir_revisores: !!data.definir_revisores, sla: toNumber(data.sla || 0) };
        const response = await this.resource.save(body, data.id);
        this.$notify({
          title: "Sucesso!",
          text: `Parametro ${data.id ? "alterado" : "criado"} com sucesso.`,
          type: "success",
          group: "geral",
          duration: 5000
        });

        this.parameters.splice(index, 1, response.data);
        this.$refs.parameters[index].resetValue(response.data);

        if (!data.id) {
          // Preciso criar o novo card vazio para cadastro no próximo ciclo de renderização
          // para que ele seja de fato criado e não reuse o elemento previamente criado na tela
          // que vai herdar o estado de validação dos campos anteriores
          setTimeout(() => {
            this.parameters.push({
              ...EMPTY_PARAMETER
            });
          }, 200);
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async getApprovers() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/nivel-hierarquicos/selecao`
        );
        const response = await get();
        this.approvers = response.sort(({ nome: a }, { nome: b }) => a.localeCompare(b));
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }

        this.approvers = [];
      }
    },
    async onEnd({ oldIndex, newIndex }) {
      try {
        const param = this.parameters[newIndex];
        await this.resource.save({ ...param, ordem: newIndex + 1 }, param.id);

        for (let i = 0; i < this.parameters.length - 1; i++) {
          this.parameters[i].ordem = i + 1;
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
        
        const [target] = this.parameters.splice(oldIndex, 1);
        this.parameters.splice(newIndex, 0, target);
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return !!relatedElement?.id && !!draggedElement.id;
    }
  }
};
</script>

<template>
  <v-card :color="tableColor" elevation="0">
    <v-card-text class="d-flex">
      <div
        aria-label="refreshButton"
        class="table-v-action-button mr-2"
        @click="doLoad"
      >
        <v-icon>mdi-refresh</v-icon>
        Atualizar
      </div>
    </v-card-text>

    <Draggable
      :list.sync="parameters"
      class="cards-container"
      handle=".grab-container"
      :move="onMove"
      @end="onEnd"
    >
      <ParameterCard
        v-for="(param, i) in parameters"
        ref="parameters"
        :approvers="approvers"
        class="parameter-card"
        :disabledDecrease="i === parameters.length - 2"
        :disabledIncrease="i === 0"
        :key="param.id || 'new'"
        :status="status"
        :value="param"
        @decreaseOrder="doReorder(param, i, i - 1)"
        @delete="doDelete($event, i)"
        @increaseOrder="doReorder(param, i, i + 1)"
        @save="doSave($event, i)"
      />
    </Draggable>
  </v-card>
</template>

<style lang="scss" scoped>
.table-v-action-button {
  user-select: none;
}

.cards-container {
  background-color: #fff;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding: 1rem 2.75rem;

  .parameter-card {
    margin-bottom: 1rem;
  }
}
</style>
