<script>
import {
  cloneDeep,
  isEqual,
  isInteger,
  isNaN,
  isNil,
  isString,
  toSafeInteger
} from "lodash";

export default {
  props: {
    approvers: {
      type: Array,
      default: () => []
    },
    disabledDecrease: Boolean,
    disabledIncrease: Boolean,
    status: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      innerValue: this.parseValue(this.value),
      isValid: true,
      originalValue: this.parseValue(this.value)
    };
  },
  computed: {
    disabledSave() {
      return !this.isValid || !this.hasChanges;
    },
    isEdit() {
      return !!this.innerValue.id;
    },
    hasChanges() {
      // Verifica se a alterações no formulário ignorando o campo ORDEM
      const current = cloneDeep(this.innerValue);
      const original = cloneDeep(this.originalValue);
      delete current.ordem;
      delete original.ordem;
      return !isEqual(current, original);
    },
    showDelete() {
      return this.isEdit;
    },
    showSave() {
      return !this.isEdit || this.hasChanges;
    }
  },
  methods: {
    decreaseOrder() {
      this.$emit("decreaseOrder");
    },
    increaseOrder() {
      this.$emit("increaseOrder");
    },
    minimun(min) {
      return (value) => {
        if (isNil(value)) {
          return true;
        }

        return toSafeInteger(value) >= min || `Valor mínimo de ${min}.`;
      }
    },
    onDelete() {
      this.$emit("delete", this.innerValue.id);
    },
    parseValue(value) {
      if (!value) {
        return value;
      }

      const { definir_revisores, sla, ...rest } = cloneDeep(value);
      return {
        ...rest,
        definir_revisores: !!definir_revisores,
        sla: isInteger(sla) ? sla : null
      };
    },
    required(type) {
      if (type === "autocomplete") {
        return value =>
          (Array.isArray(value) && value.length > 0) || "Obrigatório.";
      }

      if (type === "number") {
        return value => {
          if (isNil(value) || value === "") {
            return "Obrigatório";
          }

          if (isInteger(value)) {
            return true;
          }

          // Se for uma string, tenta converter para número
          if (isString(value)) {
            const number = Number(value);
            if (!isNaN(number) && isInteger(number)) {
              return true;
            }
          }

          return "Valor inválido";
        };
      }

      return value => !!value || "Obrigatório.";
    },
    resetValue(value) {
      if (value) {
        this.originalValue = cloneDeep(value);
        return;
      }

      this.originalValue = cloneDeep(this.innerValue);
    },
    onSave() {
      this.isValid = this.$refs.form.validate();

      if (!this.isValid) {
        return;
      }

      this.$emit("save", cloneDeep(this.innerValue));
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler(next) {
        this.$emit("update:value", next);
      }
    },
    value: {
      deep: true,
      handler(next) {
        const current = cloneDeep(this.innerValue);
        const nextValue = this.parseValue(next);

        if (!isEqual(current, nextValue)) {
          this.innerValue = nextValue;
        }
      }
    }
  }
};
</script>

<template>
  <v-card elevation="4">
    <v-form ref="form" v-model="isValid" lazy-validation>
      <div class="grab-container">
        <template v-if="isEdit">
          <v-icon>mdi-drag-horizontal</v-icon>
        </template>
      </div>

      <div class="form">
        <div class="field field-order">
          <template v-if="isEdit">
            <v-btn :disabled="disabledIncrease" icon @click="decreaseOrder">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <span>
              {{ innerValue.ordem || " — " }}
            </span>
            <v-btn :disabled="disabledDecrease" icon @click="increaseOrder">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </div>
        <div class="field">
          <span>Nome do fluxo</span>
          <v-text-field
            v-model="innerValue.nome_fluxo"
            dense
            outlined
            :rules="[required('text-field')]"
          />
        </div>
        <div class="field">
          <span>Status</span>
          <v-autocomplete
            v-model="innerValue.status"
            dense
            outlined
            :items="status"
            item-value="id"
            item-text="label"
            :rules="[required('text-field')]"
          />
        </div>
        <div class="field">
          <span>Aprovadores</span>
          <v-autocomplete
            v-model="innerValue.revisores_tipo_id"
            dense
            outlined
            :items="approvers"
            item-value="id"
            item-text="nome"
            multiple
            :rules="[required('autocomplete')]"
          />
          <div class="subfield">
            <span>Definir aprovadores?</span>
            <v-switch
              v-model="innerValue.definir_revisores"
              dense
              outlined
            />
          </div>
        </div>
        <div class="field">
          <span>Número de aprovações</span>
          <v-text-field
            v-model="innerValue.quantidade_revisores_aprovacao"
            dense
            outlined
            :rules="[required('number'), minimun(1)]"
            type="number"
          />
        </div>
        <div class="field">
          <span>SLA</span>
          <v-text-field
            v-model="innerValue.sla"
            dense
            outlined
            :rules="[required('number'), minimun(1)]"
            type="number"
          />
        </div>
      </div>

      <div class="action-button-container">
        <v-btn
          v-if="showSave"
          :color="tableColor"
          depressed
          :disabled="disabledSave"
          @click="onSave"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-btn v-if="showDelete" color="#ef4848" depressed @click="onDelete">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<style lang="scss" scoped>
.action-button-container,
.grab-container,
.form {
  display: flex;
}

.action-button-container {
  flex-direction: column;
  height: 100%;
  width: 3rem;

  &:hover {
    cursor: pointer;
  }

  .v-btn {
    border-radius: 0;
    flex: 1;
    min-width: unset;

    &:first-child {
      border-top-left-radius: 0.25rem !important;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem !important;
    }

    &.v-btn--disabled {
      background-color: unset !important;
      opacity: 0.5;
    }

    .v-icon.v-icon {
      color: #fff;
    }
  }
}

.grab-container {
  cursor: grab;
  justify-content: center;
  width: 3rem;

  &:active {
    cursor: grabbing;
  }

  .v-icon.v-icon {
    width: min-content;
  }
}

.form {
  width: 100%;

  .field {
    flex: 1;
    padding: 1rem 1rem 0 1rem;

    &:first-child {
      flex: 0.125;
    }

    &:last-child {
      flex: 0.5;
    }

    &.field-order {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      justify-content: center;
      padding: 0;

      > span {
        text-align: center;
      }
    }
  }

  .subfield {
    align-items: center;
    display: flex;
    height: min-content;
    padding-bottom: 1rem;

    .v-input--switch {
      height: 1.5rem;
      margin: 0 0 0 .5rem;
      padding: 0;
    }
  }
}

.v-form {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 8.75rem;
}
</style>
